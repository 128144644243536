// @flow
import React, {Fragment, useState} from 'react';
import {Divider, List, ListItem, ListItemButton, ListItemText, Stack, Typography} from "@mui/material";
import {RegistryService, RegistryUserType} from "../../services/registry-service";
import ConfirmDialog from "../Common/confirm-dialog";
import {BreederService} from "../../services/breeder-service";
import RouteService from "../../services/route-service";
import OnCloseNavigationSnackbar from "../Common/on-close-navigation-snackbar";
import {RegistryDto} from "../../api/generated/registry-service";
import {useMutation, useQueryClient, useSuspenseQuery} from "@tanstack/react-query";

const styles = {
    findRegistriesLayout: {
        margin: 1
    },
    fab: {
        position: "fixed",
        bottom: 5,
        right: 5,
    }
} as const;

const JoinRegistry = () => {

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [selectedRegistry, setSelectedRegistry] = useState<RegistryDto | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const {data: registries} = useSuspenseQuery({
        queryKey: ['registries', 'not-a-member'],
        queryFn: async () => await RegistryService.findAllWhereBreederIsNotMember(),
    });

    const queryClient = useQueryClient();
    const {mutateAsync: assignBreeder} = useMutation({
        mutationFn: async (registry: RegistryDto) => {
            const breederInfo = await BreederService.info();
            if (registry) {
                return await RegistryService.assignBreeder(registry.id, breederInfo.id, RegistryUserType.MEMBER);
            } else {
                //TODO: Error message on join fail!
                throw new Error('No registry selected');
            }
        },
        onSettled: async () => await queryClient.invalidateQueries({queryKey: ['registries']}),
    });

    const openConfirmationDialog = (registry: RegistryDto) => {
        setSelectedRegistry(registry);
        setConfirmDialogOpen(true);
    }

    const handleOk = async () => {
        if(selectedRegistry) {
            await assignBreeder(selectedRegistry);
            setSelectedRegistry(null);
            setSnackbarOpen(true);
        } else {
            //TODO: Error message on join fail!
        }
        setConfirmDialogOpen(false);
    }

    return (
        <Stack sx={styles.findRegistriesLayout}>
            <ConfirmDialog onClose={() => setConfirmDialogOpen(false)} onOk={handleOk} okButtonText="Beitreten" title="Diesem Register beitreten?" open={confirmDialogOpen}>
                <Typography variant="h5">{selectedRegistry?.name ?? ''}</Typography>
            </ConfirmDialog>
            <Typography variant="h3" align="center">Register beitreten</Typography>
            <List>
                {registries.map(registry =>
                    <Fragment key={registry.id}>
                        <ListItem>
                            <ListItemButton>
                                <ListItemText primary={registry.name} onClick={() => openConfirmationDialog(registry)}/>
                            </ListItemButton>
                        </ListItem>
                        <Divider variant="middle" component="li"/>
                    </Fragment>
                )}
            </List>
            <OnCloseNavigationSnackbar message="Register beigetreten!" triggerSnackbar={snackbarOpen} expandedRoute={RouteService.MEMBER_REGISTRIES} />
        </Stack>
    );
};

export default JoinRegistry
