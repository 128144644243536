// @flow
import React, {FunctionComponent, useState} from 'react';
import {Widget} from "../Common";
import {AnimalIdentifier} from "../../api/generated/herd-animal";
import {IconButton, Link, Table, TableCell, TableHead, TableRow} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import AnimalIdForm from "../Animal/animal-id-form";
import {useGlobalSnackbarStore} from "../../stores/global-snackbar-store";
import {AnimalService} from "../../services/animal-service";
import {JsonAnimal} from "../../api/generated/rest-dto";
import DeleteIcon from "@mui/icons-material/Delete";
import {getErrorMessage} from "../../errors/error-utils";
import {useTranslation} from "react-i18next";
import {InfoBox} from '../Common/alerts';

interface IdentificationsWidgetProps {
    /* We only allow editing of existing identifications by owner for unregistered animals. However, new identifications
    * can be added any time, so we don't have a flag for that. */
    editExistingAllowed?: boolean
    animal: JsonAnimal
}

const IdentificationsTable = ({animal, editExistingAllowed, openIdForm, onDeleteClick}: {
    animal: JsonAnimal,
    editExistingAllowed?: boolean,
    openIdForm: (animalIdentifier: AnimalIdentifier | undefined) => void,
    onDeleteClick: (animalIdentifier: AnimalIdentifier) => void
}) => {
    const {t} = useTranslation();

    if (!animal.panonIdentifier.animalIdentifiers.length) {
        return (
            <InfoBox>
                Keine Identifikationsnummern vorhanden. <Link href="#" onClick={(ev) => {
                    ev.preventDefault();
                    openIdForm(undefined);
                    return false;
                }}>Jetzt hinzufügen</Link>
            </InfoBox>
        );
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Nummer</TableCell>
                    <TableCell>Typ</TableCell>
                    <TableCell>Position</TableCell>
                    {editExistingAllowed ? <TableCell>Bearbeiten</TableCell> : null}
                </TableRow>
            </TableHead>
            {animal.panonIdentifier.animalIdentifiers.map(ai => (
                <TableRow key={JSON.stringify([ai.value, ai.type, ai.position])}>
                    <TableCell>{ai.value}</TableCell>
                    <TableCell>{t('identifier.type.' + ai.type)}</TableCell>
                    <TableCell>{ai.position}</TableCell>
                    {editExistingAllowed ?
                        <TableCell>
                            <IconButton onClick={() => openIdForm(ai)}>
                                <EditIcon/>
                            </IconButton>
                            <IconButton onClick={() => onDeleteClick(ai)}>
                                <DeleteIcon/>
                            </IconButton>

                        </TableCell> : null
                    }
                </TableRow>
            ))}
        </Table>
    );
};

const IdentificationsWidget: FunctionComponent<IdentificationsWidgetProps> = ({
                                                                                  animal,
                                                                                  editExistingAllowed = false
                                                                              }) => {

    const {error, success} = useGlobalSnackbarStore((state) => state);
    const [initialIdFormData, setInitialIdFormData] = useState<AnimalIdentifier | undefined>(undefined)

    const [addOpen, setAddOpen] = useState(false);

    const handleSubmit = async (original: AnimalIdentifier | undefined, updated: AnimalIdentifier) => {
        try {
            if (original) {
                if (!editExistingAllowed && AnimalService.hasAnimalIdentifier(animal, original)) {
                    error("Bestehende Identifikationsnummern können nur vom Zuchtwart oder einem Adiminstrator geändert werden!");
                    return;
                }
                await AnimalService.updateAnimalIdentifier(animal, original, updated);
            } else {
                await AnimalService.addAnimalIdentifier(animal, updated);
            }
            success("Identifikationsnummer gespeichert");
            setAddOpen(false);
        } catch (e) {
            error(getErrorMessage(e));
        }
    }


    const openIdForm = (initialData: AnimalIdentifier | undefined) => {
        setInitialIdFormData(initialData);
        setAddOpen(true);
    }

    const handleDelete = async (animalIdentifier: AnimalIdentifier) => {
        await AnimalService.deleteAnimalIdentifier(animal, animalIdentifier);
        success("Erfolgreich gelöscht");
    };

    return (
        <Widget displayName="Identifikationsnummern" cardMenuEntries={[{
            actionName: "Hinzufügen",
            actionFunction: () => openIdForm(undefined)
        }]}>
            {!addOpen
                ? <IdentificationsTable
                    animal={animal}
                    editExistingAllowed={editExistingAllowed}
                    openIdForm={openIdForm}
                    onDeleteClick={handleDelete}/>
                : <AnimalIdForm initialData={initialIdFormData} onCancelClick={() => setAddOpen(false)} onSubmit={handleSubmit}/>
            }

        </Widget>
    );
};

export default IdentificationsWidget