import React, {FC, useState} from 'react';
import Widget from "../Common/widget";
import {RouteComponentProps, withRouter} from "react-router-dom";
import TabularTextView from './Common/tabular-text-view';
import AnimalBaseDataForm from "../AnimalCreate/animal-base-data-form";
import {useGlobalSnackbarStore} from "../../stores/global-snackbar-store";
import {AnimalBaseData} from "../../api/dtos/animal-base-data";
import {useTranslation} from 'react-i18next';
import {transformAnimalBaseData} from "../../util/animal-util";
import {useDeactivateAnimal} from "./hooks/mutations";
import {Box, Button, CircularProgress, Stack, styled} from "@mui/material";
import {JsonAnimal} from "../../api/generated/rest-dto";
import {useAvatarUpload} from "../../hooks/use-avatars";
import {AnimalAvatar} from "../Common/animal-avatar";
import {UploadFile} from "@mui/icons-material";


interface BaseDataProps extends RouteComponentProps {
    initialBaseData: AnimalBaseData
    animalId?: string,
    editable?: boolean
    deactivatable?: boolean,
    onSubmit?: (animalBaseData: AnimalBaseData) => void
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ImageUpload = ({animal}: { animal: Pick<JsonAnimal, 'id' | 'name'> }) => {
    const {upload, isUploading} = useAvatarUpload(animal);

    return (
        <Button
            component="label"
            role={undefined}
            tabIndex={-1}
            disabled={isUploading}
            startIcon={isUploading ? <CircularProgress size="1em"/> : <UploadFile/>}
        >
            {isUploading ? 'Laden ...' : 'Profilbild hochladen'}
            <VisuallyHiddenInput
                type="file"
                accept=".jpg,.jpeg,.gif,.png,.webp,image/jpeg,image/gif,image/png,image/webp,image/*"
                onChange={(event) => event.target.files && upload({
                    file: event.target.files[0],
                })}/>
        </Button>
    );
};

const BaseData: FC<BaseDataProps> = ({initialBaseData, editable = true, deactivatable = false, onSubmit, animalId = 'unknown'}) => {
    const {name, herdCode} = initialBaseData;

    const {success} = useGlobalSnackbarStore((state) => state);

    const [animalBaseData, setAnimalBaseData] = useState(initialBaseData)
    const [editOpen, setEditOpen] = useState(false)
    const {t} = useTranslation();
    const {deactivateAnimal} = useDeactivateAnimal();

    const handleEditFormSubmit = async (update: AnimalBaseData) => {
        onSubmit?.(update);
        setAnimalBaseData(update);
        setEditOpen(false);
    };

    const handleAnimalDeactivate = async () =>
        deactivateAnimal(animalBaseData.panonIdentifier, {
            onSuccess: () => success("Tier wurde deaktiviert!"),
        })

    const buildCardMenuEntries = () => {
        const entries = [];
        if (editable) {
            entries.push({actionName: "Bearbeiten", actionFunction: () => setEditOpen(true)});
        }
        if (deactivatable) {
            entries.push({actionName: "Deaktivieren", actionFunction: handleAnimalDeactivate});
        }
        return entries;
    }

    const animal = {id:animalId, name: initialBaseData.name};
    return (
        <Widget displayName={<Stack direction="row">
            <Box sx={{display:'flex', alignItems: 'center'}}>
                <Box sx={{mr: 1}}>
                    <AnimalAvatar animal={animal}/>
                </Box>
                Stammdaten von {herdCode} {name}
            </Box>
        </Stack>}
                editable={editable || deactivatable}
                cardMenuEntries={buildCardMenuEntries()}>
            {editOpen ? <AnimalBaseDataForm onSubmitClicked={handleEditFormSubmit}
                                            onCancelClicked={() => setEditOpen(false)}
                                            initialData={animalBaseData}
                                            allowIdUpdate={false}/> :
                <TabularTextView displayPairs={transformAnimalBaseData(t, animalBaseData)}/>
            }
            {editable && <ImageUpload animal={animal}/>}
        </Widget>
    );
};

export default withRouter(BaseData);
