import ApiConfig from "../api/config/api-config";
import {AvatarApi} from "../api/generated/documents-avatars";
import {UpdateResult} from "../api/generated/lib-rest";

const apiConfig = new ApiConfig();
const avatarApi = new AvatarApi(apiConfig);

export interface IAvatarService {
    update(animalId: string, documentId: string): Promise<UpdateResult>;
    avatarUrl(animalId: string, nonce?: string): string;
    avatarPanonUrl(panonId: string, nonce?: string): string;
}

class AvatarServiceImpl implements IAvatarService {
    constructor(private readonly avatarApi: AvatarApi) {
    }

    async update(animalId: string, documentId: string): Promise<UpdateResult> {
        return await this.avatarApi.update(animalId, {documentId});
    }

    avatarUrl(animalId: string, nonce: string): string {
        return this.imageUrl('image', animalId, nonce);
    }

    avatarPanonUrl(panonId: string, nonce?: string): string {
        return this.imageUrl('panon/image', panonId, nonce);
    }

    private imageUrl (prefix: string, animalId: string, nonce?: string) {
        return `${apiConfig.host}/avatars/${prefix}/${animalId}?v=${nonce}`;
    }
}

export const AvatarService: IAvatarService = new AvatarServiceImpl(avatarApi);