import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {FileUpload} from "../api/generated/herds-documents";
import {JsonAnimal} from "../api/generated/rest-dto";
import {useDocumentUpload} from "./use-documents";
import {AvatarService} from "../services/avatar-service";
import {AnimalService} from "../services/animal-service";

export const useAvatarUpdate = (animal: Pick<JsonAnimal, 'id'>) => {
    const {id: animalId} = animal;
    const queryClient = useQueryClient();
    const {mutateAsync: update, isPending: isUpdating} = useMutation({
        mutationFn: async (documentId: string) => await AvatarService.update(animalId, documentId),
        onSettled: async () => await Promise.all([
            queryClient.invalidateQueries({queryKey: ['avatars', {animalId}]}),
            queryClient.invalidateQueries({queryKey: ['avatars', 'panon']}), // TODO invalidate all panon avatars?
        ])
    });
    return {update, isUpdating};
};

export const useAvatarUpload = (animal: Partial<Pick<JsonAnimal, 'herdCode'>> & Pick<JsonAnimal, 'id' | 'name'>) => {
    const herdCode = animal.herdCode ?? '';
    const {upload: uploadDocument} = useDocumentUpload();
    const {update} = useAvatarUpdate(animal);
    const {mutateAsync: upload, isPending: isUploading} = useMutation({
        mutationFn: async (fileUpload: Partial<FileUpload> & Pick<FileUpload, 'file'>) => {
            const uploadedDocument = await uploadDocument({
                title: `Profilbild für ${AnimalService.getAnimalFullName({...animal, herdCode})}`,
                note: '',
                ...fileUpload,
            });
            return await update(uploadedDocument.id);
        },
        onSettled: () => {
            // caches are invalidated in useAvatarUpdate
        },
    });
    return {upload, isUploading};
};

export const useAvatarUrl = (animalId: string) => {
    const queryInfo = useQuery({
        queryKey: ['avatars', {animalId}],
        queryFn: () => AvatarService.avatarUrl(animalId, String(+Date.now())), // executing the query will generate a new unique nonce
        staleTime: Infinity, // invalidated manually
    });
    return queryInfo.data ?? '';
};

export const useAvatarPanonUrl = (panonId: string) => {
    const queryInfo = useQuery({
        queryKey: ['avatars', 'panon', {panonId}],
        queryFn: () => AvatarService.avatarPanonUrl(panonId, String(+Date.now())), // executing the query will generate a new unique nonce
        staleTime: Infinity, // invalidated manually
    });
    return queryInfo.data ?? '';
};