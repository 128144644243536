import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {DocumentDetailsJson, FileUpload} from "../api/generated/herds-documents";
import {DocumentService} from "../services/document-service";

const EMPTY_DOCUMENTS: readonly DocumentDetailsJson[] = [] as const;

export const useDocumentDeletion = () => {
    const queryClient = useQueryClient();
    const {mutateAsync: deleteAsync, isPending: isDeleting} = useMutation({
        mutationFn: async (documentId: string) => await DocumentService.delete(documentId),
        onSettled: async () => queryClient.invalidateQueries({queryKey: ['documents']}),
    });
    return {
        delete: deleteAsync,
        isDeleting,
    };
};

export const useDocumentUpload = () => {
    const queryClient = useQueryClient();
    const {mutateAsync: upload, isPending: isUploading} = useMutation({
        mutationFn: async (fileUpload: Partial<FileUpload> & Pick<FileUpload, 'file'>) => await DocumentService.upload({
            title: fileUpload.file.name ?? 'image',
            note: '',
            ...fileUpload,
        }),
        onSettled: async () => queryClient.invalidateQueries({queryKey: ['documents']}),
    });
    return { upload, isUploading };
};

export const useDocuments = () => {
    const queryInfo = useQuery({
        queryKey: ['documents'],
        queryFn: async () => await DocumentService.list(),
    });

    const upload = useDocumentUpload();
    const deleteDocument = useDocumentDeletion();

    return {
        ...queryInfo,
        documents: queryInfo.data ?? EMPTY_DOCUMENTS,
        ...upload,
        ...deleteDocument,
    };
};