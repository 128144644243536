import {LinearProgress} from "@mui/material";
import {DateTime} from "luxon";
import {getEstimatedPregnancyDurationDays, getPregnancyDurationDays} from "./pregnancy-utils";

type PregnancyInterval = { actualEndDate: DateTime, startDate: DateTime, calculatedEndDate: DateTime };

const clamp = (value: number, min = 0, max = 100) => Math.max(Math.min(value, max), min);

const color = (pregnancy: PregnancyInterval) => pregnancy.actualEndDate
    ? 'success'
    : 'primary';

export const PregnancyProgress = ({pregnancy}: {
    pregnancy: PregnancyInterval
}) => pregnancy.startDate && pregnancy.calculatedEndDate
    ? <LinearProgress
        variant="determinate"
        color={color(pregnancy)}
        value={clamp(100 * getPregnancyDurationDays(pregnancy) / getEstimatedPregnancyDurationDays(pregnancy), 0, 150)}/>
    : <></>;

