import {DateTime} from "luxon";
import {PregnancyListJson} from "../../../api/generated/medical-rest";
import {EventBusy, Flare, SportsScoreOutlined} from "@mui/icons-material";
import {SvgIcon} from "@mui/material";
import React from "react";
import {ReactComponent as MatingDateSvg} from "../../../icons/mating_date.svg"

type PregnancyDates = Pick<PregnancyListJson, 'matingDate' | 'startDate' | 'actualEndDate'>;

export const getPregnancyDurationDays = (pregnancy: {actualEndDate: DateTime, startDate: DateTime}) => {
    const endDate = pregnancy.actualEndDate ?? DateTime.now();
    return getDurationDays({startDate: pregnancy.startDate, endDate});
};

export const getEstimatedPregnancyDurationDays = (pregnancy: {
    startDate: DateTime,
    calculatedEndDate: DateTime
}) => getDurationDays({startDate: pregnancy.startDate, endDate: pregnancy.calculatedEndDate});

export const getDurationDays = ({startDate, endDate}: {startDate: DateTime, endDate: DateTime}) => Math.round(endDate.diff(startDate).as("days"));

export enum PregnancyState {
    MATED,
    UNSUCCESSFUL,
    PREGNANT,
    COMPLETED,
}

export const getPregnancyState = ({matingDate, startDate, actualEndDate}: {
    matingDate: DateTime | undefined | null,
    startDate: DateTime | undefined | null,
    actualEndDate: DateTime | undefined | null
}): PregnancyState | null => {
    switch (true) {
        case Boolean(matingDate && !startDate && !actualEndDate): return PregnancyState.MATED;
        case Boolean(matingDate && !startDate && actualEndDate): return PregnancyState.UNSUCCESSFUL;
        case Boolean(startDate && !actualEndDate): return PregnancyState.PREGNANT;
        case Boolean(actualEndDate): return PregnancyState.COMPLETED;
        default: return null;
    }
};

export const PregnancyIcon = ({pregnancy}: { pregnancy: PregnancyDates }) => {
    const pregnancyState = getPregnancyState(pregnancy);
    switch (pregnancyState) {
        case PregnancyState.PREGNANT:return <Flare/>;
        case PregnancyState.MATED:return <SvgIcon><MatingDateSvg/></SvgIcon>;
        case PregnancyState.UNSUCCESSFUL:return <EventBusy/>;
        case PregnancyState.COMPLETED:return <SportsScoreOutlined/>;
        default:return null;
    }
}

