// @flow
import React, {Fragment, useReducer} from 'react';
import {Tree, TreeNode} from "react-organizational-chart";
import {PedigreeNodeDto} from "../../api/dtos/pedigree-dto";
import {PedigreeService} from "../../services/pedigree-service";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    IconButton,
    LinearProgress,
    Stack,
    SxProps,
    Typography
} from "@mui/material";
import {grey} from "@mui/material/colors";
import {Downloading, FileDownload, GroupAddOutlined, GroupRemoveOutlined} from "@mui/icons-material";
import {JsonAnimal} from "../../api/generated/rest-dto";
import {SexValues} from "../../api/generated/herd-animal";
import {useTranslation} from "react-i18next";
import {ErrorBox} from '../Common/alerts';
import {useQuery} from "@tanstack/react-query";
import {PedigreeDocument} from "../AnimalDashboard/Pdf/pedigree-pdf";
import {PDFDownloadLink} from "@react-pdf/renderer";

interface PedigreeProps {
    animal: JsonAnimal,
}

type PedigreeNodeProps = {
    node: PedigreeNodeDto | undefined | null;
};

type StyledNodeProps = {
    showsAncestors: boolean
    onAncestorShowHideClicked: () => void
    hasAncestors?: boolean
    node: PedigreeNodeDto | undefined
    showImage?: boolean
};


const useToggle = (initialValue: boolean) => useReducer(state => !state, initialValue);

const StyledNode: React.FC<StyledNodeProps> = ({
                                                            node,
                                                            showsAncestors,
                                                            onAncestorShowHideClicked,
                                                            hasAncestors = true,
                                                            showImage = false
                                                        }) => {
    const styles = {
        node: {
            padding: '5px',
            borderRadius: '8px',
            display: 'inline-block',
            borderTop: '3px solid',
            maxWidth: 200,
            borderTopColor: `${node && node.sex === SexValues.MALE ? "male.dark" : "female.main"}`
        } as SxProps
    };

    const {t} = useTranslation();

    const background = !node?.registries?.length ? 'warning.light' : ''
    return <Card sx={{backgroundColor: background, ...styles.node}}>
        {showImage && <CardMedia
            component="img"
            height="140"
            image="/alpaca_placeholder.jpg"
            alt="Alpaka Profilbild"
        />}
        {node && <CardContent>
            <Typography gutterBottom variant="h6" component="div">
                {node.fullName}
            </Typography>
            {node.registries.length > 0 && <Fragment>
                <Typography variant="subtitle1">
                    {t("registered_at")}
                </Typography>
                <Typography>
                    {node.registries}
                </Typography>
            </Fragment>}
        </CardContent>}
        {hasAncestors && <CardActions>
            <IconButton color="primary"
                        aria-label={showsAncestors ? 'hide ancestors' : 'show ancestors'}
                        component="label"
                        onClick={onAncestorShowHideClicked}>
                {showsAncestors ? <GroupRemoveOutlined/> : <GroupAddOutlined/>}
            </IconButton>
        </CardActions>}
    </Card>;
}

const PedigreeNode: React.FC<PedigreeNodeProps> = ({node}) => {
    // if/else cascade is needed here because if we render the inner Pedigree nodes as "null" in case dam and sire are not defined,
    // the React tree component would render connections emerging from the leafs leading to no other nodes (because no further nodes exist)

    const [showAncestors, toggleShowAncestors] = useToggle(false);

    if (!node) {
        return null;
    }

    const hasAncestors = Boolean(node.dam || node.sire);
    if (showAncestors && hasAncestors) {
        return (
            <TreeNode label={
                <StyledNode node={node} showsAncestors={true} onAncestorShowHideClicked={toggleShowAncestors}/>
            }>
                <PedigreeNode node={node.dam}/>
                <PedigreeNode node={node.sire}/>
            </TreeNode>
        )
    } else {
        return <TreeNode label={
            <StyledNode node={node} showsAncestors={false} onAncestorShowHideClicked={toggleShowAncestors} hasAncestors={hasAncestors}/>
        }/>
    }
}

const Pedigree: React.FC<PedigreeProps> = ({animal}) => {

    const [showRootAncestors, toggleShowRootAncestors] = useToggle(true);

    const {data: pedigree, isLoading, isError} = useQuery({
        queryKey: ['pedigree', {id: animal?.id}],
        queryFn: () => PedigreeService.loadPedigree(animal),
    });

    if (isLoading) {
        return <LinearProgress />;
    }

    if (isError) {
        return <ErrorBox>Fehler beim Laden des Stammbaums.</ErrorBox>
    }

    if (!pedigree) {
        return <Typography>Keine Stammbauminformation vorhanden!</Typography>;
    }

    const rootNode = pedigree.root;
    return (
        <Stack gap={1}>
            <Box sx={{
                overflowX: "auto"
            }}>
                <Tree
                    lineWidth="2px"
                    lineColor={grey.A400}
                    lineBorderRadius="10px"
                    label={<StyledNode node={rootNode}
                                       showsAncestors={showRootAncestors}
                                       onAncestorShowHideClicked={toggleShowRootAncestors}/>}
                >
                    {showRootAncestors && pedigree.root.dam && <PedigreeNode node={pedigree.root.dam}/>}
                    {showRootAncestors && pedigree.root.sire && <PedigreeNode node={pedigree.root.sire}/>}
                </Tree>
            </Box>
            <PDFDownloadLink document={<PedigreeDocument pedigree={pedigree}/>}
                             fileName={`${animal.herdCode}_${animal.name}_pedigree.pdf`}
                             style={{marginLeft:'auto'}}>
                {({loading}) => (
                    <Button startIcon={loading ? <Downloading/> : <FileDownload/>}
                            variant="contained"
                            disabled={loading}>
                        {loading ? 'PDF wird generiert' : 'Stammbaum als PDF downloaden'}
                    </Button>
                )}
            </PDFDownloadLink>
        </Stack>
    );
};

export default Pedigree
