import {ApiConfigPort} from '../config/api-config-port';
import {HttpService} from '../../services/http-service';
import {CreateResult, DeleteResult, ListResult} from './lib-rest';

export class DocumentApi {
  config: ApiConfigPort;


 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async delete(id: string): Promise<DeleteResult>  {
const url =  new URL('/documents/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().delete(url.toString())).data
  }

 public  async get(id: string): Promise<number[]>  {
const url =  new URL('/documents/file/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async getDetails(id: string): Promise<DocumentDetailsJson>  {
const url =  new URL('/documents/details/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async list(): Promise<ListResult<DocumentDetailsJson> >  {
const url =  new URL('/documents', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async upload(fileUpload: FileUpload): Promise<CreateResult>  {
const url =  new URL('/documents', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
if(fileUpload.file !== undefined){queryParamsList.push({name: 'file', value: fileUpload.file});}
if(fileUpload.note !== undefined){queryParamsList.push({name: 'note', value: fileUpload.note});}
if(fileUpload.title !== undefined){queryParamsList.push({name: 'title', value: fileUpload.title});}

    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().post(url.toString())).data
  }

}

export interface DocumentDetailsJson {
  contentType: string;
  filename: string;
  id: string;
  note: string;
  title: string;
  uploadedAt: any;
}

export interface FileUpload {
  file: any;
  note: string;
  title: string;
}

