import React, {FC} from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FormattedDate from '../Common/formatted-date';
import {Avatar, SxProps, Table, TableBody, TableCell, TableContainer, TableRow, Theme} from "@mui/material";
import RouteService from "../../services/route-service";
import I18NService from "../../services/i18n-service";
import {JsonAnimal} from "../../api/generated/rest-dto";
import {useAvatarUrl} from "../../hooks/use-avatars";
import {useNav} from '../Common/hooks/use-nav';
import {AnimalService} from "../../services/animal-service";

interface AnimalCardProps {
  data: JsonAnimal
  withImage?: boolean
  sx?: SxProps<Theme>
}

const styles: Record<string, SxProps> = {
  card: {
    display: 'inline-block'
  },
  media: {
    height: 300
  },
  infolist: {
    margin: 0,
    padding: 0
  },
  right: {
    textAlign: 'right'
  },
  left: {
    textAlign: 'left',
    fontWeight: 'bold'
  }
};

const EMPTY_SX = {} as const;

const AnimalCard: FC<AnimalCardProps> = ({data, withImage = false, sx = EMPTY_SX}) => {
  const nav = useNav();

  const handleCardClick = () => nav.push(RouteService.ANIMAL_DETAILS, {panonId: data.panonIdentifier.id});
  const avatarUrl = useAvatarUrl(data.id);

  return (
      <Card sx={[styles.card, ...(Array.isArray(sx) ? sx : [sx])]}>
        <CardActionArea onClick={handleCardClick}>
          <CardContent>
            <Typography gutterBottom
                        variant="h5"
                        component="h2"
                        sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              {withImage && <Avatar alt={data.name} src={avatarUrl} sx={{height: 64, width: 64, mr: 1}}/>}
              {AnimalService.getAnimalFullName(data)}
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  <TableRow hover>
                    <TableCell sx={styles.left}>Geschlecht:</TableCell>
                    <TableCell sx={styles.right}>{I18NService.translateSex(data.sex)}</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell sx={styles.left}>Geburtsdatum:</TableCell>
                    <TableCell sx={styles.right}><FormattedDate sx={styles.right} date={data.dateOfBirth}/></TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell sx={styles.left}>Farbe:</TableCell>
                    <TableCell sx={styles.right}>{I18NService.translateFiberColor(data.fiberColor)}</TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell sx={styles.left}>Id:</TableCell>
                    <TableCell sx={styles.right}>{data.chipId}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </CardActionArea>
      </Card>
  );
}

export default AnimalCard;
