import React, {useState} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import BaseData from './base-data-widget';
import TREATMENT_TYPES from "../../api/dtos/treatment-type";
import Tags from "./Tags";
import TreatmentList from "./TreatmentList";
import PregnancyWidget from "./Pregnancy/pregnancy-widget";
import RenderOnSex from "../Common/render-on-sex";
import MatingWidget from "./Pregnancy/mating-widget";
import RegistryWidget from "../Registry/registry-widget";
import {JsonAnimal} from "../../api/generated/rest-dto";
import TreatmentService from "../../services/treatment-service";
import {TreatmentListJson} from "../../api/generated/medical-rest";
import {PregnancyService} from "../../services/pregnancy-service";
import {AnimalRecordStatusValues, SexValues} from "../../api/generated/herd-animal";
import PedigreeWidget from "./Pedigree/pedigree-widget";
import FlexStack from "../Common/flex-stack";
import WeightChart from "./WeightChart";
import {DateTime} from "luxon";
import IdentificationsWidget from "./identifications-widget";
import {AnimalBaseData, transformFromJsonAnimal} from "../../api/dtos/animal-base-data";
import {useGlobalSnackbarStore} from "../../stores/global-snackbar-store";
import {useAnimal} from "../Common/hooks/use-animal";
import {useRegistrations} from "../Common/hooks/use-registrations";
import {LinearProgress, Skeleton} from "@mui/material";
import {Widget} from '../Common';
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {Apps, FileDownload} from "@mui/icons-material";
import SpeedDial from "@mui/material/SpeedDial";
import RouteService from "../../services/route-service";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {usePendingRegistrations} from "../Registry/use-pending-registrations";
import {useNav} from '../Common/hooks/use-nav';
import {useUpdateBaseData} from "./hooks/mutations";

const pregnancyTreatmentTypes = [TREATMENT_TYPES.spittest, TREATMENT_TYPES.ultrasound].map(String);
const isPregnancyTreatment = (t: TreatmentListJson) => pregnancyTreatmentTypes.includes(t.type);

const useTreatments = (animal: JsonAnimal | undefined) => {
    const queryClient = useQueryClient();

    const queryInfo = useQuery({
        queryKey: ['treatments', animal?.id],
        queryFn: async () => TreatmentService.loadTreatmentsForAnimalId(animal!.id),
        enabled: !!animal,
    });

    const {mutate: deleteTreatment} = useMutation({
        mutationKey: ['treatments'],
        mutationFn: async (treatmentId: string) => treatmentId ? TreatmentService.deleteTreatment(treatmentId) : Promise.resolve(),
        onSettled: async () => queryClient.invalidateQueries({queryKey: ['treatments']}),
    });

    return {
        ...queryInfo,
        treatments: queryInfo.data,
        deleteTreatment,
    };
};

const usePregnancies = (animal: JsonAnimal | undefined) => {
    const queryClient = useQueryClient();

    const queryInfo = useQuery({
        queryKey: ['pregnancies', animal?.panonIdentifier.id],
        queryFn: () => PregnancyService.listByParent(animal!.panonIdentifier.id),
        enabled: !!animal,
    });

    const {mutate: deletePregnancy} = useMutation({
        mutationKey: ['pregnancies'],
        mutationFn: async (pregnancyId: string) => await PregnancyService.deletePregnancy(pregnancyId),
        onSettled: async () => queryClient.invalidateQueries({queryKey: ['pregnancies']}),
    });

    return {
        ...queryInfo,
        pregnancies: queryInfo.data,
        deletePregnancy,
    };
};

const SpeedActions = ({panonId}:{panonId:string}) => {
    const [speedDialOpen, setSpeedDialOpen] = useState(false);
    const nav = useNav();

    const handlePrintPdf = () => nav.push(RouteService.ANIMAL_DETAILS_PDF, {panonId});
    const handleNewOwner = () => nav.push(RouteService.ANIMAL_TRANSFER_OWNERSHIP, {panonId});

    return (
        <SpeedDial
            ariaLabel="Schnellzugriff"
            sx={{position: 'fixed', bottom: 16, right: 16}}
            icon={<Apps/>}
            onClose={() => setSpeedDialOpen(false)}
            onOpen={() => setSpeedDialOpen(true)}
            open={speedDialOpen}
        >
            <SpeedDialAction
                tooltipTitle="PDF-Download"
                tooltipOpen
                icon={<FileDownload/>}
                onClick={handlePrintPdf}
            />
            <SpeedDialAction
                tooltipTitle="Neuer Besitzer"
                tooltipOpen
                icon={<SwapHorizIcon/>}
                onClick={handleNewOwner}
            />

        </SpeedDial>
    );
};

type AnimalDashboardProps = {
    panonId: string
};

const AnimalDashboard: React.FC<RouteComponentProps<AnimalDashboardProps>> = ({match}) => {

    const {error, success} = useGlobalSnackbarStore((state) => state);

    const {animal, isLoading} = useAnimal(match.params.panonId);
    const {treatments = [], isLoading: treatmentsLoading, deleteTreatment} = useTreatments(animal);
    const {pregnancies = [], isLoading: pregnanciesLoading, deletePregnancy} = usePregnancies(animal);
    const {registrations: animalRegistrations = [], isLoading: registrationsLoading} = useRegistrations(animal?.panonIdentifier.id);
    const {data: pendingRegistrations} = usePendingRegistrations(animal?.panonIdentifier.id);

    const {updateBaseData} = useUpdateBaseData(animal);

    if (isLoading || treatmentsLoading || pregnanciesLoading || registrationsLoading) {
        return (
            <>
                <LinearProgress/>
                <FlexStack spacing={3}>
                    <Widget displayName="Stammdaten">
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                        <Skeleton/>
                    </Widget>
                </FlexStack>
            </>
        );
    }

    if (!animal) {
        return null;
    }

    const timestampedTreatments = treatments.filter(t => t.timestamp !== null);
    const animalIsRegistered = animalRegistrations.length !== 0;

    const weights = timestampedTreatments
        .filter(t => t.type === TREATMENT_TYPES.weighing)
        .map(weighing => ({
            date: weighing.timestamp as DateTime,
            dateString: weighing.timestamp.toLocaleString(),
            weight: weighing.value,
            note: weighing.note,
        }));

    const bodyscores = timestampedTreatments
        .filter(t => t.type === TREATMENT_TYPES.bodyscore)
        .map(scoring => ({
            date: scoring.timestamp as DateTime,
            dateString: scoring.timestamp.toLocaleString(),
            bodyscore: scoring.value,
            note: scoring.note,
        }));

    const handleBaseDataSubmit = async (update: AnimalBaseData) => updateBaseData(update, {
        onSuccess: () => success('Daten gespeichert!'),
        onError: () => error('Aktualisierung der Stammdaten fehlgeschlagen!'),
    });

    const pregnancyTreatments = treatments.filter(t => isPregnancyTreatment(t));

    return (
        <FlexStack spacing={3} key={animal.id}>
            <Tags animal={animal}/>
            <BaseData onSubmit={handleBaseDataSubmit}
                      initialBaseData={transformFromJsonAnimal(animal)}
                      animalId={animal.id}
                      deactivatable={animal.recordStatus === AnimalRecordStatusValues.HERD}/>
            <IdentificationsWidget animal={animal} editExistingAllowed={!animalIsRegistered}/>
            <RegistryWidget animal={animal} animalRegistrations={animalRegistrations} pendingRegistrations={pendingRegistrations}/>
            <WeightChart animal={animal} weights={weights} bodyscores={bodyscores}/>
            <TreatmentList animal={animal} treatments={treatments} onTreatmentDelete={deleteTreatment}/>
            <RenderOnSex requiredSex={SexValues.FEMALE} animal={animal}>
                <PregnancyWidget
                    animal={animal}
                    pregnancies={pregnancies}
                    treatments={pregnancyTreatments}
                    onDelete={deletePregnancy}
                    onTreatmentDelete={deleteTreatment}/>
            </RenderOnSex>
            <RenderOnSex requiredSex={SexValues.MALE} animal={animal}>
                <MatingWidget animal={animal} matings={pregnancies}/>
            </RenderOnSex>
            <PedigreeWidget animal={animal} isRegistered={animalIsRegistered}/>

            <SpeedActions panonId={animal.panonIdentifier.id}/>
        </FlexStack>
    );

}

export default withRouter(AnimalDashboard);
