import {ApiConfigPort} from '../config/api-config-port';
import {HttpService} from '../../services/http-service';
import {UpdateResult} from './lib-rest';

export class AvatarApi {
  config: ApiConfigPort;


 public  async avatar(id: string): Promise<number[]>  {
const url =  new URL('/avatars/image/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async panonAvatar(panonId: string): Promise<number[]>  {
const url =  new URL('/avatars/panon/image/' + panonId + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async update(animalId: string, avatarDocumentId: AvatarController$AvatarDocumentId): Promise<UpdateResult>  {
const url =  new URL('/avatars/update/' + animalId + '', this.config.host);

return (await HttpService.getAxiosClient().put(url.toString(),JSON.stringify(avatarDocumentId))).data
  }

}

export interface AvatarController$AvatarDocumentId {
  documentId: string;
}

