// @flow
import React, {FunctionComponent} from 'react';
import {IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';
import PregnancyDateRange from "./pregnancy-date-range";
import DeleteIcon from '@mui/icons-material/Delete';
import RouteService from "../../../services/route-service";
import {JsonAnimal} from "../../../api/generated/rest-dto";
import {PregnancyListJson} from "../../../api/generated/medical-rest";
import {ParentType, ParentTypeValues} from "../../../api/generated/herds-pedigree";
import {usePartners} from "./hooks/use-partners";
import {DateTime} from "luxon";
import {ErrorBox} from '../../Common/alerts';
import {PregnancyIcon} from "./pregnancy-utils";
import {useBulkAnimals} from "../../../hooks/use-bulk-animals";
import {AnimalAvatar} from "../../Common/animal-avatar";
import Avatar from "@mui/material/Avatar";
import { PregnancyProgress } from './pregnancy-progress';

type Props = {
    pregnancies: PregnancyListJson[],
    baseParentKind: ParentType,
    renderEndDate?: boolean,
    onDelete?: (pregnancyId: string) => void
};

type PregnancyListItemProps = {
    pregnancy: PregnancyListJson,
    partner: JsonAnimal | undefined,
    renderEndDate: boolean,
    onDelete?: (pregnancyId: string) => void,
    animalDetails?: Map<string, JsonAnimal>,
}


const getSecondary = (renderEndDate: boolean, pregnancy: PregnancyListJson) => {
    if (!pregnancy.startDate) {
        const daysAgo = Math.round(DateTime.now().diff(pregnancy.matingDate).as("days"));
        const suffix = pregnancy.actualEndDate ? '(erfolglos)' : `(vor ${daysAgo} Tagen)`;
        return <>gedeckt am {pregnancy.matingDate.toLocaleString()} {suffix}</>
    }

    const duration = renderEndDate ? <PregnancyDateRange pregnancy={pregnancy}/> : pregnancy.startDate.toLocaleString()
    if (pregnancy.matingDate) {
        return <>{duration}, gedeckt am {pregnancy.matingDate.toLocaleString()}</>;
    }
    return duration;
};

const PregnancyListItem: FunctionComponent<PregnancyListItemProps> = ({partner, pregnancy, renderEndDate, animalDetails, onDelete = null}) => {
    const partnerName = partner ? `${partner.herdCode} ${partner.name}` : '';
    const secondary = getSecondary(renderEndDate, pregnancy);

    const offspring = pregnancy.offspringId ? animalDetails?.get(pregnancy.offspringId) : undefined;

    return (
        <ListItem secondaryAction={
            onDelete !== undefined
            && onDelete !== null
            && <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => onDelete(pregnancy.id)}
                size="large">
                <DeleteIcon/>
            </IconButton>
        }>
            <ListItemButton component={RouterLink}
                            to={{pathname: RouteService.expand(RouteService.PREGNANCY_DETAILS, {pregnancyId: pregnancy.id})}}>
                <ListItemAvatar>
                    {offspring
                        ? <AnimalAvatar animal={offspring}/>
                        : <Avatar><PregnancyIcon pregnancy={pregnancy}/></Avatar>}
                </ListItemAvatar>
                <ListItemText
                    primary={offspring?.name}
                    secondary={<>
                        <div>Partner: {partnerName}</div>
                        <div>{secondary}</div>
                        {pregnancy.startDate && <div><PregnancyProgress pregnancy={pregnancy}/></div>}
                    </>}/>
            </ListItemButton>
        </ListItem>
    );

}

const PregnancyList: FunctionComponent<Props> = ({pregnancies, baseParentKind, renderEndDate = true, onDelete}) => {

    const {partners, isLoading, isError} = usePartners(pregnancies, baseParentKind);
    const {animals} = useBulkAnimals(pregnancies.map(p => p.offspringId).filter((id): id is string => !!id));

    if (isLoading) {
        return <LinearProgress />;
    }

    if (isError) {
        return <ErrorBox>Fehler beim Laden der Trächtigkeiten.</ErrorBox>;
    }

    if (!partners) {
        return <ErrorBox>Trächtigkeiten können nicht angezeigt werden.</ErrorBox>;
    }

    return (
        <List>
            {pregnancies.map((p) => {
                const partner = partners.get(baseParentKind === ParentTypeValues.DAM ? (p.fatherAnimalId ?? '') : p.motherAnimalId);
                return <PregnancyListItem key={p.id}
                                          renderEndDate={renderEndDate}
                                          partner={partner}
                                          pregnancy={p}
                                          onDelete={onDelete}
                                          animalDetails={animals}/>;
            })}
        </List>
    );
};

export default PregnancyList
