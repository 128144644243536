// @flow
import React, {FunctionComponent} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {DateTime} from "luxon";
import {DatePicker} from "@mui/x-date-pickers";
import useFormFields from "../../Common/hooks/use-form-fields";
import {Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

type Props = {
    open: boolean;
    startDate?: DateTime;
    onSave: (confirmedDate: DateTime, calculatedEndDate: DateTime) => void;
    onCancel: () => void;
    title: string
};

const DEFAULT_PREGNANCY_DURATION = 350;

const ConfirmPregnancyDialog: FunctionComponent<Props> = ({
                                                              open,
                                                              startDate = DateTime.now(),
                                                              onSave,
                                                              onCancel,
                                                              title
                                                          }) => {
    const {formFields, createChangeHandler} = useFormFields({
        startDate: startDate,
        duration: DEFAULT_PREGNANCY_DURATION,
    });

    const calculatedEndDate = formFields.startDate.plus({days: formFields.duration ?? 0});

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby='form-dialog-title'>
            <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
            <DialogContent>

                <Stack spacing={2}>
                    <DatePicker
                        format="MM/dd/yyyy"
                        label="Trächtigkeitsbeginn"
                        value={formFields.startDate}
                        onChange={createChangeHandler("startDate")}
                    />

                    <TextField type="number"
                               label="Erwartete Schwangerschaftsdauer"
                               slotProps={{
                                   input: {
                                       endAdornment: <InputAdornment position="end">Tage</InputAdornment>,
                                       slotProps: {
                                           input: {
                                               min: 0,
                                           },
                                       },
                                   },
                               }}
                               value={formFields.duration}
                               onChange={createChangeHandler("duration", value => !isNaN(value) ? parseInt(value) : 0)}/>

                    <DatePicker
                        disabled
                        format="MM/dd/yyyy"
                        label="Errechneter Geburtstermin"
                        value={calculatedEndDate}
                    />
                </Stack>

            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="secondary">Abbrechen</Button>
                <Button onClick={() => onSave(formFields.startDate, calculatedEndDate)}
                        color="primary"
                        variant="contained">
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmPregnancyDialog
