// @flow
import * as React from 'react';
import {Widget} from "../Common";
import RouteService from "../../services/route-service";
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {JsonAnimal} from "../../api/generated/rest-dto";
import {RegistrationJson, RegistrationRequestController$PendingRequestJson} from "../../api/generated/registry-rest";
import AnimalRegistrationsList from "./animal-registrations-list";
import {InfoBox} from "../Common/alerts";

type RegistryWidgetProps = {
    animal: JsonAnimal,
    animalRegistrations: RegistrationJson[],
    pendingRegistrations: RegistrationRequestController$PendingRequestJson[],
};

const RegistryWidget: React.FC<RegistryWidgetProps> = ({animal, animalRegistrations, pendingRegistrations}) => {
    const history = useHistory();

    const applyToRegistryRoute = RouteService.expand(RouteService.ANIMAL_REGISTRY_APPLICATION_SELECT, {panonId: animal.panonIdentifier.id});
    return <Widget displayName="Registrierungen"
                   cardMenuEntries={[
                       {
                           actionName: "Registrierung beantragen", actionFunction: () => {
                               history.push(applyToRegistryRoute)
                           }
                       }
                   ]}>
        <AnimalRegistrationsList herdAnimal={animal} animalRegistrations={animalRegistrations} pendingRegistrations={pendingRegistrations}/>
        {!animalRegistrations.length && !pendingRegistrations.length
            && <InfoBox>Tier noch nicht registriert. <RouterLink to={{pathname:applyToRegistryRoute}}>Registrierung beantragen</RouterLink></InfoBox>
        }
    </Widget>;
};

export default RegistryWidget;
