import React, {FC, useState} from 'react';
import {RouteComponentProps, useLocation, withRouter} from 'react-router-dom';
import {PedigreeService} from "../services/pedigree-service";
import AnimalCard from '../components/AnimalCard';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {JsonAnimal} from "../api/generated/rest-dto";
import FlexStack from "../components/Common/flex-stack";
import {SexValues} from "../api/generated/herd-animal";
import {useGlobalSnackbarStore} from "../stores/global-snackbar-store";
import ParentPicker from '../components/Pedigree/parent-picker';
import RouteService from "../services/route-service";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {JsonPedigreeRecord} from "../api/generated/pedigree-rest";
import {useBulkAnimals} from "../hooks/use-bulk-animals";
import {LoadingFallback} from "../components/Common/loading-fallback";
import {ErrorBox} from "../components/Common/alerts";

const usePedigreeRecord = (panonId: string | undefined) => {
    const queryInfo = useQuery({
        queryKey: ['pedigree-record', {panonId}],
        queryFn: async () => PedigreeService.loadPedigreeRecord(panonId!),
        enabled: !!panonId,
    });

    return {
        ...queryInfo,
        pedigree: queryInfo.data ?? {damId: '', panonId: panonId!, sireId: ''},
    };
};

type AnimalAncestryCardProps = {
    panonId: string
};

interface AncestryChangeLocationState {
    expandedRedirectUrl?: string
}

const ParentSelector = ({panonId, sire, dam}: { panonId: string ,sire?:JsonAnimal, dam?: JsonAnimal;}) => {
    const [selectedDam, setSelectedDam] = useState(dam);
    const [selectedSire, setSelectedSire] = useState(sire);

    const {state: locationState} = useLocation<AncestryChangeLocationState>();
    const {success, error} = useGlobalSnackbarStore(state => state);

    const queryClient = useQueryClient();
    const {mutateAsync: upsertPedigreeRecord} = useMutation({
        mutationFn: async (pedigreeRecord: JsonPedigreeRecord) => await PedigreeService.upsertPedigreeRecord(pedigreeRecord),
        onSettled: () => Promise.all([
            queryClient.invalidateQueries({queryKey: ['pedigree']}),
            queryClient.invalidateQueries({queryKey: ['pedigrees']}),
            queryClient.invalidateQueries({queryKey: ['pedigree-record']}),
        ]),
        onError: () => error("Aktualisierung fehlgeschlagen"),
        onSuccess: result => {
            if (result.info === "success") {
                const redirectUrl = locationState?.expandedRedirectUrl
                    ? locationState.expandedRedirectUrl
                    : RouteService.expand(RouteService.ANIMAL_DETAILS, {panonId});
                success("Stammbaum gespeichert!", redirectUrl);
            } else {
                error("Aktualisierung fehlgeschlagen!");
            }
        },
    });

    const handleParentSelected = (parent: JsonAnimal) => {
        switch (parent.sex) {
            case SexValues.FEMALE:
                setSelectedDam(parent);
                break;
            case SexValues.MALE:
                setSelectedSire(parent);
                break;
            default:
                error("Das ausgewählte Elterntier war weder männlich noch weiblich!");
        }
    }

    const handleSaveClick = async () => await upsertPedigreeRecord({
        damId: selectedDam?.panonIdentifier?.id ?? '',
        sireId: selectedSire?.panonIdentifier?.id ?? '',
        panonId
    });

    return (
        <>
            <ParentPicker sire={selectedSire} dam={selectedDam} onParentSelected={handleParentSelected}/>
            <Button variant="contained" onClick={handleSaveClick}>Speichern</Button>
        </>
    )
};

const AnimalAncestry: FC<RouteComponentProps<AnimalAncestryCardProps>> = ({match}) => {
    const panonId = match.params.panonId;

    const {pedigree, isLoading: isPedigreeLoading} = usePedigreeRecord(panonId);

    const {animals, isLoading: isAnimalLoading} = useBulkAnimals([panonId, pedigree.damId, pedigree.sireId].filter(Boolean));
    const offspring = animals.get(panonId);
    const dam = animals.get(pedigree.damId);
    const sire = animals.get(pedigree.sireId);

    if (isAnimalLoading || isPedigreeLoading) {
        return (
            <FlexStack spacing={3}>
                <Typography variant="h3" align="center">Stammbaum bearbeiten</Typography>
                <LoadingFallback/>
            </FlexStack>
        );
    }

    if (!offspring) {
        return <ErrorBox>Fehler beim Laden des Stammbaums</ErrorBox>;
    }

    return (
        <FlexStack spacing={3}>
            <Typography variant="h3" align="center">Stammbaum bearbeiten</Typography>
            <AnimalCard data={offspring}/>
            <ParentSelector key={String([dam?.id, sire?.id])} panonId={panonId} sire={sire} dam={dam}/>
        </FlexStack>
    );
}

export default withRouter(AnimalAncestry);
