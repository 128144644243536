// @flow
import React, {FunctionComponent, useState} from 'react';
import {JsonAnimal} from "../../api/generated/rest-dto";
import {
    Avatar,
    Dialog,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import AnimalListItem from "../Animal/animal-list-item";
import AnimalSearch from "../Common/animal-search";
import {SexValues} from "../../api/generated/herd-animal";
import AnimalSearchResult from "../../api/dtos/animal-search-result";
import {AnimalService} from "../../services/animal-service";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import PedigreeAnimalCreateCard from "./pedigree-animal-create-card";
import {AnimalBaseData, transformToCreateAnimalDto} from "../../api/dtos/animal-base-data";

interface ParentPickerProps {
    sire?: JsonAnimal
    dam?: JsonAnimal
    offspringPanonId?: string
    sireEditable?: boolean
    damEditable?: boolean
    onParentSelected: (selectedParent: JsonAnimal) => void
};

interface SecondaryEditActionProps {
    onParentEdit: () => void
};

const SecondaryEditAction: FunctionComponent<SecondaryEditActionProps> = ({onParentEdit}) => {
    return (
        <IconButton onClick={onParentEdit} edge="end" aria-label="delete">
            <EditIcon/>
        </IconButton>);
}

const PlaceholderListItem: FunctionComponent<SecondaryEditActionProps> = ({onParentEdit}) => {
    return (<ListItem secondaryAction={<SecondaryEditAction onParentEdit={onParentEdit}/>} disablePadding>
        <ListItemAvatar>
            <Avatar alt="?" src="/placeholder"/>
        </ListItemAvatar>
        <ListItemText primary="Unknown"/>
    </ListItem>)
}

const EditListItem = ({animal, editable, handleEditClick}: { animal: JsonAnimal | undefined, editable: boolean, handleEditClick: () => void }) => {
    if (!animal) {
        return <PlaceholderListItem onParentEdit={handleEditClick}/>;
    }

    const secondaryAction = editable
        ? <SecondaryEditAction onParentEdit={handleEditClick}/>
        : null;

    return <AnimalListItem animal={animal} secondaryAction={secondaryAction}/>;
}

const ParentPicker: React.FC<ParentPickerProps> = ({
                                                                sire,
                                                                dam,
                                                                offspringPanonId,
                                                                sireEditable = true,
                                                                damEditable = true,
                                                                onParentSelected
                                                            }) => {
    const [open, setOpen] = React.useState(false);
    const [parentSex, setParentSex] = useState<SexValues>(SexValues.MALE)

    const handleParentEditClick = (sex: SexValues) => {
        setParentSex(sex);
        setOpen(true);
    }

    const handleBeforeShowSuggestions = (results: AnimalSearchResult[]): AnimalSearchResult[] => {
        return results
            .filter(r => r.sex === parentSex)
            //animal cannot be its own parent (only applies if offspringPanonId parameter was set)
            .filter(r => !offspringPanonId || r.panonId.id !== offspringPanonId);
    }

    const handleSearchCardClick = async (animalSearchResult: AnimalSearchResult) => {
        handleClose();
        onParentSelected(await AnimalService.loadJsonAnimalByPanonId(animalSearchResult.panonId.id));
    }

    const handleSireEditClick = () => handleParentEditClick(SexValues.MALE)
    const handleDamEditClick = () => handleParentEditClick(SexValues.FEMALE)

    const handleClose = () => setOpen(false);

    const handlePedigreeAnimalCreate = async (animalToCreate: AnimalBaseData) => {
        handleClose();
        const result = await AnimalService.createForPedigree(transformToCreateAnimalDto(animalToCreate));
        if(result.info === "created"){
            onParentSelected(await AnimalService.loadJsonAnimalById(result.id));
        }
    }

    return (
        <Paper>
            <List sx={{paddingLeft: 2}}>
                <Typography>Deckhengst</Typography>
                <EditListItem animal={sire} editable={sireEditable} handleEditClick={handleSireEditClick} />
                <Typography>Stute</Typography>
                <EditListItem animal={dam} editable={damEditable} handleEditClick={handleDamEditClick} />
            </List>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                        <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                            {parentSex === SexValues.MALE ? "Hengst suchen" : "Stute suchen"}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <AnimalSearch label="Tiername"
                              onBeforeShowSuggestions={handleBeforeShowSuggestions}
                              handleResultClick={(animalSearchResult) => {
                                  handleSearchCardClick(animalSearchResult)
                              }}
                              renderOnEmptyResult={<PedigreeAnimalCreateCard onSubmit={handlePedigreeAnimalCreate}/>}
                />
            </Dialog>
        </Paper>
    );
};

export default ParentPicker