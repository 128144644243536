import React from 'react';
import {Widget} from "../../Common";
import {JsonAnimal} from "../../../api/generated/rest-dto";
import {useHistory} from "react-router-dom";
import RouteService from "../../../services/route-service";
import TreatmentType from "../../../api/dtos/treatment-type";
import {
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    ResponsiveContainer,
    Tooltip,
    TooltipProps,
    XAxis,
    YAxis
} from 'recharts';
import {
    Box,
    Paper,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {DateTime} from "luxon";
import {Balance, Filter3} from "@mui/icons-material";
import Tabbed from '../../Common/tabbed';

interface WeightChartProps {
    animal: JsonAnimal,
    weights: ChartPoint[],
    bodyscores: ChartPoint[],
}

type ChartPoint = {
    date: DateTime;
    dateString: string;
    weight?: number;
    bodyscore?: number;
    note: string;
    delta?: number;
};

const CustomTooltip = ({active, payload}: TooltipProps<string, string>) => {
    if (active && payload?.length) {
        const data = payload[0].payload;
        return (
            <Box>
                <p>Datum: {DateTime.isDateTime(data.date) ? data.date.toLocaleString() : data.date}</p>
                <p>Gewicht: {data.weight}kg</p>
                {data.note && <p>Notiz: {data.note}</p>}
            </Box>
        );
    }

    return null;
};

const legendStyles: SxProps = {
    display: "flex",
    gap: 1,
    justifyContent: "center",
    ".weight": {
        color: "#8884d8"
    },
    ".bodyscore": {
        color: "#777b27"
    }
} as const;

type ChartProps = { data: ChartPoint[] };

const ResponsiveWeightChart = ({data}: ChartProps) => (
    <ResponsiveContainer>
        <ComposedChart
            width={500}
            height={300}
            data={data}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="dateString"/>
            <YAxis type="number" unit="kg" domain={[0, 120]} yAxisId="weight" orientation="left"/>
            <YAxis type="number" unit="" domain={[0, 6]} yAxisId="bodyscore" orientation="right"/>
            <Tooltip content={<CustomTooltip/>}/>
            <Legend content={
                <Box sx={legendStyles}>
                    <Typography className="weight">Gewicht</Typography>
                    <Typography className="bodyscore">Bodyscore</Typography>
                </Box>
            }/>
            <Line name="Gewicht" yAxisId="weight" strokeWidth={3} type="monotone" dataKey="weight" stroke="#8884d8"
                  activeDot={{r: 8}} connectNulls/>
            <Line name="Bodyscore" yAxisId="bodyscore" strokeWidth={2} type="monotone" dataKey="bodyscore" stroke="#777b27"
                  activeDot={{r: 8}} connectNulls/>
        </ComposedChart>
    </ResponsiveContainer>
);

const WeightTable = ({data}: ChartProps) => (
    <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Datum</TableCell>
                    <TableCell>Gewicht</TableCell>
                    <TableCell>Bodyscore</TableCell>
                    <TableCell>Notiz</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map(row => {
                    const isLosingWeight = (row.delta ?? 0) < 0;
                    return (
                        <TableRow key={row.dateString}>
                            <TableCell>{row.dateString}</TableCell>
                            <TableCell sx={isLosingWeight ? {color: 'red'} : {}}>
                                {row.weight === undefined ? '—' : `${row.weight} kg`}
                            </TableCell>
                            <TableCell>{row.bodyscore ?? '—'}</TableCell>
                            <TableCell>{row.note}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    </TableContainer>
);

const findMinDate = (points: ChartPoint[]) => {
    let minDate = points[0]?.date;
    for (const point of points) {
        if (point.date < minDate) minDate = point.date;
    }
    return minDate;
};

const findMaxDate = (points: ChartPoint[]) => {
    let maxDate = points[0]?.date;
    for (const point of points) {
        if (point.date > maxDate) maxDate = point.date;
    }
    return maxDate;
};

const spaceDataPoints = (dataPoints: ChartPoint[]) => {
    const minDate = findMinDate(dataPoints);
    const maxDate = findMaxDate(dataPoints);
    const range = Math.round(maxDate?.diff(minDate).as('days'));

    const dummyDataPoints: ChartPoint[] = [];
    if (minDate !== undefined) {
        for (let i = 0; i < range; ++i) {
            const date = minDate.plus({days: i});
            const point: ChartPoint = {
                date,
                dateString: date.toLocaleString(),
                note: '',
            };
            dummyDataPoints.push(point);
        }
    }

    return [...dataPoints, ...dummyDataPoints];
};

const compareDates = <T extends { date: DateTime; }>(a: T, b: T) => a.date.toMillis() - b.date.toMillis();

const WeightChart = (props: WeightChartProps) => {
    const history = useHistory();

    const weights = [...props.weights]
        .sort(compareDates)
        .map((weight, idx, weights) => ({
            ...weight,
            delta: (weight.weight ?? 0) - (weights[idx - 1]?.weight ?? 0),
        }));

    const dataPoints = [
        ...weights,
        ...props.bodyscores,
    ].sort(compareDates);
    const spacedDataPoints = spaceDataPoints(dataPoints);

    const pointsByDate = new Map<string, ChartPoint>();
    for (let chartPoint of spacedDataPoints) {
        const key = chartPoint.dateString;
        const value = pointsByDate.get(key) ?? {...chartPoint};
        value.weight ??= chartPoint.weight;
        value.bodyscore ??= chartPoint.bodyscore;
        pointsByDate.set(key, value);
    }

    const mergedDataPoints = Array.from(pointsByDate.values())
        .sort((a, b) => a.date.toMillis() - b.date.toMillis());

    const recordTreatment = (treatmentType: TreatmentType) => history.push({
        pathname: RouteService.expand(RouteService.TREATMENT_EDIT, {animalId: props.animal.id}),
        search: `?treatmentType=${treatmentType}&panonId=${props.animal.panonIdentifier.id}`,
    });

    const recordWeightAction = () => recordTreatment(TreatmentType.weighing);
    const recordBodyScoreAction = () => recordTreatment(TreatmentType.bodyscore);

    return <Widget displayName="Gewicht"
                   cardMenuEntries={[{
                       actionName: "Gewicht erfassen",
                       actionFunction: recordWeightAction
                   }, {
                       actionName: "Bodyscore eintragen",
                       actionFunction: recordBodyScoreAction,
                   }]}
                   quickActions={[{
                       icon: <Balance/>,
                       action: recordWeightAction,
                   }, {
                       icon: <Filter3 />,
                       action: recordBodyScoreAction,
                   }]}>
        <Tabbed>
            <Tabbed.Tab label="Chart">
                <Box sx={{height: "300px", width: "100%"}}>
                    <ResponsiveWeightChart data={mergedDataPoints}/>
                </Box>
            </Tabbed.Tab>
            <Tabbed.Tab label="Tabelle">
                <WeightTable data={dataPoints}/>
            </Tabbed.Tab>
        </Tabbed>
    </Widget>;
};

export default WeightChart;