import ApiConfig from "../api/config/api-config";
import {DocumentApi, DocumentDetailsJson, FileUpload} from "../api/generated/herds-documents";
import {CreateResult, DeleteResult} from "../api/generated/lib-rest";
import HttpService from "./http-service";
import {DateTime} from "luxon";

export interface IDocumentService {
    list(): Promise<DocumentDetailsJson[]>;

    upload(fileUpload: FileUpload): Promise<CreateResult>;

    download(id: string): Promise<Blob>;

    delete(id: string): Promise<DeleteResult>;

    generateBlobUrl(blob: Blob): string;

    releaseBlobUrl(url: string): void;
}

// manual API impl until generator supports file uploads and blob responses
class ExtendedDocumentApi extends DocumentApi {
    public async getBlob(id: string): Promise<Blob>  {
        const url =  new URL(`/documents/file/${id}`, this.config.host);

        return (await HttpService.getAxiosClient().get(url.toString(), {responseType: 'blob'})).data;
    }

    public async upload(fileUpload: FileUpload): Promise<CreateResult> {
        const url = new URL('/documents', this.config.host);
        if (fileUpload.note !== undefined) {
            url.searchParams.append('note', fileUpload.note);
        }
        if (fileUpload.title !== undefined) {
            url.searchParams.append('title', fileUpload.title);
        }

        const formData = new FormData();
        formData.append("file", fileUpload.file);

        const response = await HttpService.getAxiosClient().post(
            url.toString(),
            formData,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
        return response.data
    }
}

class DocumentServiceImpl implements IDocumentService {
    constructor(private readonly documentApi: ExtendedDocumentApi) {
    }

    async list(): Promise<DocumentDetailsJson[]> {
        return (await this.documentApi.list()).items.map(item => ({
            ...item,
            uploadedAt: DateTime.fromISO(item.uploadedAt),
        }));
    }

    async upload(fileUpload: FileUpload): Promise<CreateResult> {
        return await this.documentApi.upload(fileUpload);
    }

    async download(id: string): Promise<Blob> {
        return await this.documentApi.getBlob(id);
    }

    async delete(id: string): Promise<DeleteResult> {
       return await this.documentApi.delete(id);
    }

    generateBlobUrl(blob: Blob): string {
        return window.URL.createObjectURL(blob);
    }

    releaseBlobUrl(url: string): void {
        window.URL.revokeObjectURL(url);
    }
}

const documentApi = new ExtendedDocumentApi(new ApiConfig());
export const DocumentService: IDocumentService = new DocumentServiceImpl(documentApi);