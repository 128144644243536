import {ApiConfigPort} from '../config/api-config-port';
import {BirthSearchRequest, PregnancySearchRequest, TreatmentFilter} from './herd-medical';
import {HttpService} from '../../services/http-service';
import {CreateResult, DeleteResult, ListResult, Result, UpdateResult} from './lib-rest';

export interface ConfirmPregnancyJson {
  calculatedEndDate: any;
  startDate: any;
}

export interface CreatePregnancyJson {
  actualEndDate: any | null;
  calculatedEndDate: any;
readonly   damId: string;
  fatherAnimalId: string | null;
  motherAnimalId: string;
  note: string;
readonly   sireId: string | null;
  startDate: any;
}

export interface CreateTreatmentJson {
  animalId: string;
  dose: number;
  drug: string;
  note: string;
  timestamp: any;
  type: string;
  unit: string;
  value: number;
}

export class PregnancyApi {
  config: ApiConfigPort;


 public  async active(damId: string): Promise<ListResult<PregnancyListJson> >  {
const url =  new URL('/pregnancies/active', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'damId', value: damId});
  
    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async confirmPregnancy(id: string, json: ConfirmPregnancyJson): Promise<Result>  {
const url =  new URL('/pregnancies/' + id + '/confirm', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(json))).data
  }

 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async create(json: CreatePregnancyJson): Promise<CreateResult>  {
const url =  new URL('/pregnancies', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(json))).data
  }

 public  async delete(id: string): Promise<DeleteResult>  {
const url =  new URL('/pregnancies/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().delete(url.toString())).data
  }

 public  async details(id: string): Promise<PregnancyDetailJson>  {
const url =  new URL('/pregnancies/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async find(request: PregnancySearchRequest): Promise<ListResult<PregnancyDetailJson> >  {
const url =  new URL('/pregnancies', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
if(request.parentId !== undefined){queryParamsList.push({name: 'parentId', value: request.parentId});}
if(request.pregnantAtDate !== undefined){queryParamsList.push({name: 'pregnantAtDate', value: request.pregnantAtDate});}

    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async latest(parentId: string): Promise<PregnancyDetailJson>  {
const url =  new URL('/pregnancies/latest', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'parentId', value: parentId});
  
    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async list(request: PregnancySearchRequest): Promise<ListResult<PregnancyListJson> >  {
const url =  new URL('/pregnancies/list', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
if(request.parentId !== undefined){queryParamsList.push({name: 'parentId', value: request.parentId});}
if(request.pregnantAtDate !== undefined){queryParamsList.push({name: 'pregnantAtDate', value: request.pregnantAtDate});}

    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async listBirths(request: BirthSearchRequest): Promise<ListResult<PregnancyListJson> >  {
const url =  new URL('/pregnancies/births', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
if(request.after !== undefined){queryParamsList.push({name: 'after', value: request.after});}
if(request.before !== undefined){queryParamsList.push({name: 'before', value: request.before});}

    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async recordDateOfBirth(id: string, json: RecordDateOfBirthRequestJson): Promise<UpdateResult>  {
const url =  new URL('/pregnancies/' + id + '/recordDateOfBirth', this.config.host);

return (await HttpService.getAxiosClient().put(url.toString(),JSON.stringify(json))).data
  }

 public  async recordMating(json: RecordMatingJson): Promise<CreateResult>  {
const url =  new URL('/pregnancies/matings', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(json))).data
  }

 public  async recordMiscarriage(id: string, json: RecordDateOfBirthRequestJson): Promise<UpdateResult>  {
const url =  new URL('/pregnancies/' + id + '/recordMiscarriage', this.config.host);

return (await HttpService.getAxiosClient().put(url.toString(),JSON.stringify(json))).data
  }

}

export interface PregnancyDetailJson {
  actualEndDate: any;
  calculatedEndDate: any;
readonly   damId: string;
  fatherAnimalId: string | null;
  matingDate: any | null;
  motherAnimalId: string;
  note: string;
  offspringId: string | null;
readonly   sireId: string | null;
  startDate: any | null;
}

export interface PregnancyListJson {
  actualEndDate: any;
  calculatedEndDate: any;
readonly   damId: string;
  fatherAnimalId: string | null;
  id: string;
  matingDate: any | null;
  motherAnimalId: string;
  offspringId: string | null;
readonly   sireId: string | null;
  startDate: any | null;
}

export interface RecordDateOfBirthRequestJson {
  dateOfBirth: any;
}

export interface RecordMatingJson {
  damId: string;
  matingDate: any;
  note: string;
  sireId: string;
}

export class TreatmentApi {
  config: ApiConfigPort;


 public constructor(config: ApiConfigPort) {
    this.config = config;
  }

 public  async create(treatment: CreateTreatmentJson): Promise<CreateResult>  {
const url =  new URL('/treatments', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(treatment))).data
  }

 public  async createBulk(treatments: CreateTreatmentJson[]): Promise<Result>  {
const url =  new URL('/treatments', this.config.host);

return (await HttpService.getAxiosClient().post(url.toString(),JSON.stringify(treatments))).data
  }

 public  async delete(id: string): Promise<DeleteResult>  {
const url =  new URL('/treatments/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().delete(url.toString())).data
  }

 public  async list(treatmentFilter: TreatmentFilter): Promise<ListResult<TreatmentListJson> >  {
const url =  new URL('/treatments', this.config.host);
    const queryParamsList: { name: string, value: string }[] = [];
if(treatmentFilter.animalId !== undefined){queryParamsList.push({name: 'animalId', value: treatmentFilter.animalId});}
if(treatmentFilter.interval.end !== undefined){queryParamsList.push({name: 'interval.end', value: treatmentFilter.interval.end});}
if(treatmentFilter.interval.start !== undefined){queryParamsList.push({name: 'interval.start', value: treatmentFilter.interval.start});}
if(treatmentFilter.type !== undefined){queryParamsList.push({name: 'type', value: treatmentFilter.type});}

    for (const queryParam of queryParamsList) {
url.searchParams.append(queryParam.name,queryParam.value);
    }

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async show(id: string): Promise<TreatmentDetailJson>  {
const url =  new URL('/treatments/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().get(url.toString())).data
  }

 public  async update(id: string, json: TreatmentDetailJson): Promise<UpdateResult>  {
const url =  new URL('/treatments/' + id + '', this.config.host);

return (await HttpService.getAxiosClient().put(url.toString(),JSON.stringify(json))).data
  }

}

export interface TreatmentDetailJson {
  animalId: string;
  dose: number;
  drug: string;
  note: string;
  timestamp: any;
  type: string;
  unit: string;
  value: number;
}

export interface TreatmentListJson {
  animalId: string;
  dose: number;
  drug: string;
  id: string;
  note: string;
  timestamp: any;
  type: string;
  unit: string;
  value: number;
}

