import {BreederSearchResult} from "../../api/generated/herd-breeder";
import {Avatar, LinearProgress, ListItem, ListItemAvatar, Skeleton, Stack} from "@mui/material";
import List from "@mui/material/List";
import {ErrorBox, InfoBox} from "../Common/alerts";
import React from "react";
import {useQuery} from "@tanstack/react-query";
import {BreederService} from "../../services/breeder-service";
import BreederList from "../Common/breeder-list";
import {useTranslation} from "react-i18next";
import Tabbed from "../Common/tabbed";

const useBreeders = (searchTerm: string) => {
    const queryInfo = useQuery({
        queryKey: ['breeders', searchTerm],
        queryFn: async () => BreederService.search(searchTerm),
        enabled: !!searchTerm,
    });

    return {
        ...queryInfo,
        breeders: queryInfo.data,
    };
};

const LoadingItem = () => (
    <ListItem>
        <ListItemAvatar><Avatar/></ListItemAvatar>
        <Skeleton width="100%"/>
    </ListItem>
);

export const FilteredBreeders = ({searchTerm, onBreederClick, emptyResultRender}: {
    searchTerm: string;
    onBreederClick: (breeder: BreederSearchResult) => void;
    emptyResultRender?: React.ReactNode
}) => {
    const {t} = useTranslation();
    const {breeders = [], isLoading, isError} = useBreeders(searchTerm);

    if (isLoading) {
        return (
            <Stack>
                <LinearProgress/>

                <Tabbed>
                    <Tabbed.Tab label="Alle (...)">
                        <List>
                            <LoadingItem/>
                            <LoadingItem/>
                            <LoadingItem/>
                        </List>
                    </Tabbed.Tab>
                </Tabbed>
            </Stack>
        );
    }

    if (!searchTerm) {
        return <InfoBox>Suchbegriff eingeben, um die Suche zu starten</InfoBox>;
    }

    if (isError) {
        return <ErrorBox>Fehler beim Laden der Züchter</ErrorBox>;
    }

    if (!breeders.length) {
        return emptyResultRender || <InfoBox>{t('breeders.notFound')}</InfoBox>;
    }

    return (
        <Tabbed>
            <Tabbed.Tab label={`Alle (${breeders.length})`}>
                <BreederList breeders={breeders} onListItemClick={onBreederClick} />
            </Tabbed.Tab>
        </Tabbed>
    );
};