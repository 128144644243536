// @flow
import React, {FunctionComponent} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableRow, Typography} from "@mui/material";

interface TabularTextViewProps {
    displayPairs: Map<string, string>
}

const TabularTextView: FunctionComponent<TabularTextViewProps> = ({displayPairs}) => {
    return (
        <TableContainer>
            <Table size="small">
                <TableBody>
                    {Array.from(displayPairs.entries()).map(([key, value]) => (
                        <TableRow key={key} hover>
                            <TableCell>
                                <Typography variant="subtitle1" color="text.secondary">
                                    {key}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle1" color="text.secondary">
                                    {value}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TabularTextView