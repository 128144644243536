import {DateTime} from "luxon";
import ApiConfig from "../api/config/api-config";
import {
    ConfirmPregnancyJson,
    CreatePregnancyJson,
    PregnancyApi,
    PregnancyDetailJson,
    PregnancyListJson,
    RecordMatingJson
} from "../api/generated/medical-rest";
import {PregnancySearchRequest} from "../api/generated/herd-medical";
import {CreateResult, DeleteResult, Result, UpdateResult} from "../api/generated/lib-rest";
import {JsonAnimal} from "../api/generated/rest-dto";
import {AnimalService} from "./animal-service";
import {ParentType, ParentTypeValues} from "../api/generated/herds-pedigree";


export interface IPregnancyService {
    findParent(p: PregnancyListJson | PregnancyDetailJson, parentToLoad: ParentType): Promise<JsonAnimal | null>;

    findFather(p: PregnancyListJson | PregnancyDetailJson): Promise<JsonAnimal | null>;

    findMother(p: PregnancyListJson | PregnancyDetailJson): Promise<JsonAnimal | null>;

    findPregnancyById(pId: string): Promise<PregnancyDetailJson>;

    recordBirth(id: string, dateOfBirth: DateTime): void;

    recordMiscarriage(id: string, dateOfBirth: DateTime): Promise<UpdateResult>;

    listByParent(routeAnimalId: string): Promise<PregnancyListJson[]>;

    listBirths(after: DateTime, before: DateTime): Promise<PregnancyListJson[]>;

    recordMating(mating: RecordMatingJson): Promise<CreateResult>;

    createPregnancy(pregnancy: CreatePregnancyJson): Promise<CreateResult>;

    confirmPregnancy(id: string, confirmation: ConfirmPregnancyJson): Promise<Result>;

    deletePregnancy(pregnancyId: string): Promise<DeleteResult>;
}

export class PregnancyServiceImpl implements IPregnancyService {
    constructor(private readonly _pregnancyApi: PregnancyApi) {
    }

    async findParent(p: {
                         motherAnimalId: PregnancyListJson['motherAnimalId'],
                         fatherAnimalId: PregnancyListJson['fatherAnimalId'],
                     },
                     parentToLoad: ParentType): Promise<JsonAnimal | null> {
        const panonId = parentToLoad === ParentTypeValues.DAM ? p.motherAnimalId : p.fatherAnimalId;
        if (!panonId) {
            return null;
        }
        return await AnimalService.loadJsonAnimalByPanonId(panonId);
    }

    async findFather(p: PregnancyListJson | PregnancyDetailJson): Promise<JsonAnimal | null> {
        return await this.findParent(p, ParentTypeValues.SIRE);
    }

    async findMother(p: PregnancyListJson | PregnancyDetailJson): Promise<JsonAnimal | null> {
        return await this.findParent(p, ParentTypeValues.DAM);
    }

    async findPregnancyById(pId: string): Promise<PregnancyDetailJson> {
        return this._pregnancyApi.details(pId);
    }

    recordBirth(id: string, dateOfBirth: DateTime) {
        return this._pregnancyApi.recordDateOfBirth(id, {dateOfBirth: dateOfBirth.toUTC()})
    };

    recordMiscarriage(id: string, dateOfMiscarriage: DateTime) {
        return this._pregnancyApi.recordMiscarriage(id, {dateOfBirth: dateOfMiscarriage.toUTC()});
    }

    async listByParent(routeAnimalId: string): Promise<PregnancyListJson[]> {
        const pregnancySearchRequest: PregnancySearchRequest = {
            parentId: routeAnimalId,
            pregnantAtDate: undefined,
        };

        return (await this._pregnancyApi.list(pregnancySearchRequest)).items;
    }

    async listActive(parentId?: string): Promise<PregnancyListJson[]> {
        const pregnancySearchRequest: PregnancySearchRequest = {
            parentId: parentId as unknown as string,
            pregnantAtDate: DateTime.now().toISODate(),
        };

        return (await this._pregnancyApi.list(pregnancySearchRequest)).items;
    }

    async listActivePregnancies(damId: string): Promise<PregnancyListJson[]> {
        return (await this._pregnancyApi.active(damId)).items;
    }

    async listBirths(after: DateTime, before?: DateTime) {
        const births = (await this._pregnancyApi.listBirths({after: after.toISODate(), before: before?.toISODate() ?? undefined})).items;
        births.sort((a, b) => {
            const end1 = a.actualEndDate ?? a.calculatedEndDate;
            const end2 = b.actualEndDate ?? b.calculatedEndDate;
            if (end1 === end2) {
                return 0;
            }
            return end1 < end2 ? 1 : -1;
        });
        return births;
    }

    recordMating(mating: RecordMatingJson): Promise<CreateResult> {
        return this._pregnancyApi.recordMating({
            ...mating,
            matingDate: mating.matingDate.toISODate(),
        });
    }

    async createPregnancy(pregnancy: CreatePregnancyJson): Promise<CreateResult> {

        let startDate = pregnancy.startDate.toISODate();
        let actualEndDate = pregnancy.actualEndDate ? pregnancy.actualEndDate.toISODate() : null;
        let calculatedEndDate = pregnancy.calculatedEndDate.toISODate();
        const data: any = {...pregnancy, startDate, actualEndDate, calculatedEndDate}

        return await this._pregnancyApi.create(data);
    }

    confirmPregnancy(id: string, confirmation: ConfirmPregnancyJson): Promise<Result> {
        return this._pregnancyApi.confirmPregnancy(id, {
            ...confirmation,
            startDate: confirmation.startDate.toISODate(),
            calculatedEndDate: confirmation.calculatedEndDate.toISODate(),
        });
    }

    async deletePregnancy(pregnancyId: string): Promise<DeleteResult> {
        return await this._pregnancyApi.delete(pregnancyId);
    }
}

const pregnancyApi: PregnancyApi = new PregnancyApi(new ApiConfig())

export const PregnancyService = new PregnancyServiceImpl(pregnancyApi);