import {PregnancyListJson} from "../../../../api/generated/medical-rest";
import {ParentType, ParentTypeValues} from "../../../../api/generated/herds-pedigree";
import {JsonAnimal} from "../../../../api/generated/rest-dto";
import {useQuery} from "@tanstack/react-query";
import {AnimalService} from "../../../../services/animal-service";

const getPartnerIds = (pregnancies: PregnancyListJson[], baseParentKind: ParentType) =>
    pregnancies
        .map(p => baseParentKind !== ParentTypeValues.DAM ? p.motherAnimalId : p.fatherAnimalId)
        .filter((id): id is string => !!id);

export const usePartners = (pregnancies: PregnancyListJson[], baseParentKind: ParentType) => {
    const queryInfo = useQuery({
        queryKey: ['partners', {
            baseParentKind,
            pregnancyIds: pregnancies.map(p => p.id).sort((a, b) => a.localeCompare(b))
        }],
        queryFn: async () => await AnimalService.loadJsonAnimalsByPanonIds(getPartnerIds(pregnancies, baseParentKind)),
        select: partners => new Map(partners.filter((p): p is JsonAnimal => !!p).map(partner => [partner.panonIdentifier.id, partner])),
    });

    return {
        ...queryInfo,
        partners: queryInfo.data,
    };
};
