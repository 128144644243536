// @flow
import React, {Fragment, FunctionComponent} from 'react';
import FlexCard from "./flex-card";
import {Avatar, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography} from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import RouteService from "../../services/route-service";
import {RequestRegistrationController$AnimalSimilarityJson} from "../../api/generated/registry-rest";
import {JsonAnimal} from "../../api/generated/rest-dto";
import {PanonIdentifier} from "../../api/generated/herd-animal";
import {useNav} from "../Common/hooks/use-nav";
import {useBulkAnimals} from "../../hooks/use-bulk-animals";

interface ReviewSimilaritiesCardProps {
    similarAnimals: RequestRegistrationController$AnimalSimilarityJson[],
    reviewedAnimal: JsonAnimal,
    reviewId: string
}

const ReviewSimilaritiesCard: FunctionComponent<ReviewSimilaritiesCardProps> = ({reviewId, reviewedAnimal, similarAnimals}) => {
    const nav = useNav();

    const handleSimilarityCardClick = (reviewId: string, similarAnimal: JsonAnimal) => {
        nav.push(
            RouteService.REGISTRY_ANIMAL_REVIEW_COMPARE_SIMILARITIES,
            {
                reviewedAnimalPanonId: reviewedAnimal.panonIdentifier.id,
                similarAnimalPanonId: similarAnimal.panonIdentifier.id
            },
            {
                reviewedAnimal,
                similarAnimal
            });
    }

    const findMatchingSaByPanonId = (panonId: PanonIdentifier): RequestRegistrationController$AnimalSimilarityJson | undefined => similarAnimals.find(simAnimal => simAnimal.panonIdentifier === panonId.id);

    const {data: similarAnimalsDetails = new Map<string, JsonAnimal>()} = useBulkAnimals(similarAnimals.map(sa => sa.panonIdentifier));

    const renderOverlappingPropertiesIndicator = (animal: JsonAnimal) => {
        const matching: RequestRegistrationController$AnimalSimilarityJson | undefined = findMatchingSaByPanonId(animal.panonIdentifier);
        if (matching && matching.score > 4) {
            return (<ErrorIcon alignmentBaseline="baseline" fontSize="large" color="warning"/>)
        } else if (matching) {
            return (
                <Typography variant="subtitle2" color="text.primary" sx={{display: 'inline'}}
                            component="span">
                    {matching.score}
                </Typography>
            )
        }
        return null;

    }

    //TODO avatarUrl is needed in JsonAnimal

    return (
        <FlexCard title="Ähnlich zu">
            <List sx={{width: '100%', bgcolor: 'background.paper'}}>
                {Array.from(similarAnimalsDetails.values()).map(sa =>
                    <>
                        <ListItem alignItems="flex-start" >
                            <ListItemButton onClick={() => handleSimilarityCardClick(reviewId, sa)}>
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src="/frosty.jpg"/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={sa.herdCode + " " + sa.name + " - " + sa.dateOfBirth.toLocaleString()}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{display: 'inline'}}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                Ähnlichkeiten:
                                            </Typography>
                                            {renderOverlappingPropertiesIndicator(sa)}
                                        </React.Fragment>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li"/>
                    </>
                )}
            </List>
        </FlexCard>
    );
};

export default ReviewSimilaritiesCard
