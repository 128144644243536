import {AnimalService} from "../../../services/animal-service";
import {useQuery, useSuspenseQuery} from "@tanstack/react-query";

export const useAnimal = (panonId: string | undefined) => {
    const queryInfo = useQuery({
        queryKey: ['animal', panonId],
        queryFn: () => AnimalService.loadJsonAnimalByPanonId(panonId!),
        enabled: !!panonId,
    });

    return {
        ...queryInfo,
        animal: queryInfo.data,
    };
};

export const useSuspenseAnimal = (panonId: string | undefined) => {
    const {data, isLoading, isError} = useSuspenseQuery({
        queryKey: ['animal', panonId],
        queryFn: () => panonId ? AnimalService.loadJsonAnimalByPanonId(panonId) : null,
    });

    return {
        animal: data,
        isLoading,
        isError,
    };
};
