import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";
import React from "react";

interface TabbedHerdState {
    selectedTab: number;
    setSelectedTab: (event: React.SyntheticEvent, index: number) => void;
}

export const useTabbedHerdStore = create<TabbedHerdState>()(
    persist(
        (set) => ({
            selectedTab: 0,
            setSelectedTab: (event: React.SyntheticEvent, index: number) => set(() => ({selectedTab: index})),
        }),
        {
            name: 'tabbedHerdStore',
            storage: createJSONStorage(() => sessionStorage)}
    )
)

