import React, {FunctionComponent} from 'react';
import IsoIcon from '@mui/icons-material/Iso';
import {PregnancyListJson} from "../../../api/generated/medical-rest";
import {getEstimatedPregnancyDurationDays, getPregnancyDurationDays} from "./pregnancy-utils";

const PregnancyDateRange: FunctionComponent<{ pregnancy: PregnancyListJson }> = ({pregnancy}) => {
    return (
        <span>
            {pregnancy.startDate.toLocaleString()} - {
            pregnancy.actualEndDate
                ? pregnancy.actualEndDate.toLocaleString()
                : <><IsoIcon sx={{verticalAlign: "bottom"}}/>{pregnancy.calculatedEndDate.toLocaleString()}</>
        } ({getPregnancyDurationDays(pregnancy)}/{getEstimatedPregnancyDurationDays(pregnancy)} Tage)
        </span>
    );
}

export default PregnancyDateRange;