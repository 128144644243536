import {JsonAnimal} from "../api/generated/rest-dto";
import {useQuery} from "@tanstack/react-query";
import {AnimalService} from "../services/animal-service";

export const useBulkAnimals = (ids: string[]) => {
    const distinctIds = Array.from(new Set(ids)).sort((a, b) => a.localeCompare(b));
    const queryInfo = useQuery({
        queryKey: ['animals', {ids: distinctIds}],
        queryFn: async () => await AnimalService.loadJsonAnimalsByPanonIds(distinctIds),
        enabled: !!ids.length,
        select: animals => new Map(animals.map(animal => [animal.panonIdentifier.id, animal])),
    });

    return {
        ...queryInfo,
        animals: queryInfo.data ?? new Map<string, JsonAnimal>(),
    };
};
